import React from 'react';

function Covid19(): JSX.Element {
  return (
    <>
    </>
  );
}

export default Covid19;

enum QuantumColors {
  pumpkin = '#ff7518',
  lightGray1 = '#F7F7F8',
  lightGray2 = '#D7D8DE',
  lightGray3 = '#C7C8CE',
  lightGray4 = '#cccccc',
  darkGray = '#3D3A37',
  darkGray2 = '#75706A',
  black = '#000000',
  errorRed = '#ff0000',
  green = 'rgb(76, 175, 80)',
}

export default QuantumColors;

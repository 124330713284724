enum RoutePaths {
  Epic = '/services/quantum-EPIC',
  EP = '/services/executive-protetion',
  Intelligence = '/services/intelligence',
  Command = '/services/quantum-command',
  Fire = '/services/fire-suppression',
  Consulting = '/services/security-consulting',
  EventSecurity = '/services/event-security',
  Investigations = '/services/investigations',
  Transport = '/services/high-value-transport',
  UniformedPatrol = '/services/uniformed-patrol',
  B2B = '/services/business-to-business',
  Company = '/company',
  Contact = '/contact',
  Login = '/login-redirect',
  Covid19 = '/covid-19',
}

export default RoutePaths;

import React from 'react';
import BusinessToBusiness from '../pages/services/BusinessToBusiness';
import Consulting from '../pages/services/Consulting';
import EventSecurity from '../pages/services/EventSecurity';
import ExecutiveProtection from '../pages/services/ExecutiveProtection';
import FireSuppression from '../pages/services/FireSuppression';
import HighValueTransport from '../pages/services/HighValueTransport';
import Intelligence from '../pages/services/Intelligence';
import Investigations from '../pages/services/Investigations';
import QuantumCommand from '../pages/services/QuantumCommand';
import QuantumEPIC from '../pages/services/QuantumEPIC';
import UniformedPatrol from '../pages/services/UniformedPatrol';
import Company from '../pages/Company';
import Contact from '../pages/Contact';
import RoutePaths from './RoutePaths';
import Covid19 from '../pages/Covid19';

interface IRoute {
  component: JSX.Element;
  path: string;
}

const RouteData: IRoute[] = [
  {
    component: <BusinessToBusiness />,
    path: RoutePaths.B2B,
  },
  {
    component: <Consulting />,
    path: RoutePaths.Consulting,
  },
  {
    component: <EventSecurity />,
    path: RoutePaths.EventSecurity,
  },
  {
    component: <ExecutiveProtection />,
    path: RoutePaths.EP,
  },
  {
    component: <FireSuppression />,
    path: RoutePaths.Fire,
  },
  {
    component: <HighValueTransport />,
    path: RoutePaths.Transport,
  },
  {
    component: <Intelligence />,
    path: RoutePaths.Intelligence,
  },
  {
    component: <Investigations />,
    path: RoutePaths.Investigations,
  },
  {
    component: <QuantumCommand />,
    path: RoutePaths.Command,
  },
  {
    component: <QuantumEPIC />,
    path: RoutePaths.Epic,
  },
  {
    component: <UniformedPatrol />,
    path: RoutePaths.UniformedPatrol,
  },
  {
    component: <Company />,
    path: RoutePaths.Company,
  },
  {
    component: <Contact />,
    path: RoutePaths.Contact,
  },
  {
    component: <Covid19 />,
    path: RoutePaths.Covid19,
  },
];

export default RouteData;

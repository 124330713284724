import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Header from './components/Header';
import Homepage from './pages/Homepage';
import Footer from './components/Footer';
import RouteData from './data/RouteData';
import PreviewLogin from './components/PreviewLogin';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function App(): JSX.Element {
  const classes = useStyles();
  const [loggedIn, setLoggedIn] = useState(false);

  const appElement: JSX.Element = (
    <div className={classes.root}>
      <Router>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            <Switch>
              {RouteData.map((item) => (
                <Route path={item.path} key={item.component.toString()}>
                  {item.component}
                </Route>
              ))}
              <Route path="/">
                <Homepage />
              </Route>
            </Switch>
          </Grid>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      </Router>
    </div>
  );

  if (loggedIn) {
    return appElement;
  }

  return <PreviewLogin setLoggedIn={setLoggedIn} />;
}

export default App;

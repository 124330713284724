import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import logo from '../assets/quantum-logo-orange-white.svg';
import { servicesMenuItemsData } from '../data/ServicesMenuData';
import QuantumColors from '../data/QuantumColors';
import RoutePaths from '../data/RoutePaths';

const useStyles = makeStyles({
  appBar: {
    backgroundColor: QuantumColors.darkGray,
    boxShadow: 'none',
    borderBottom: `1px solid ${QuantumColors.lightGray4}`,
    position: 'relative',
  },
  toolbar: {
    width: '100%',
    margin: '0 auto',
    padding: '0',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
  },
  gridContainer: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  logo: {
    textDecoration: 'none',
    height: '54px',
    padding: '5px 0 5px 10px',
  },
  menuIconContainer: {
    height: '100%',
    textAlign: 'center',
  },
  icon: {
    color: '#D3CDC6',
    fontSize: '40px',
    height: '100%',
    '&:hover': {
      color: QuantumColors.pumpkin,
    },
  },
  list: {
    width: '275px',
    backgroundColor: QuantumColors.darkGray,
    height: '100%',
    borderLeft: '2px solid #1C1815',

    '& hr': {
      backgroundColor: 'rgba(247, 247, 248, .2)',
    },
  },
  listItem: {
    color: QuantumColors.lightGray1,
    fontSize: '20px',

    '&:hover': {
      color: QuantumColors.pumpkin,
      '--fa-primary-color': QuantumColors.pumpkin,
      '--fa-secondary-color': QuantumColors.lightGray1,
    },

    '& a': {
      textDecoration: 'none',
      color: QuantumColors.lightGray1,
      display: 'flex',
    },
  },
  itemIcon: {
    display: 'inline-block',
    width: 35,
  },
  epicIcon: {
    '--fa-primary-color': QuantumColors.pumpkin,
  },
  disabled: {
    color: QuantumColors.darkGray2,
  },
});

function MobileToolbar(): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown'
      && ((event as React.KeyboardEvent).key === 'Tab'
      || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen((prevOpen) => !prevOpen);
  };

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>
        <ListItem button className={`${classes.listItem} ${classes.disabled}`}>
          <Link to={RoutePaths.Login}>
            <span className={classes.itemIcon}>
              <i className="fad fa-user-circle" />
            </span>
            <ListItemText primary="Login" disableTypography />
          </Link>
        </ListItem>
        <Divider />
        {servicesMenuItemsData.map((item) => (
          <ListItem button key={item.name} className={classes.listItem}>
            <Link to={item.path}>
              <span className={classes.itemIcon}>
                {item.icon}
              </span>
              <ListItemText primary={item.name} disableTypography />
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem button className={classes.listItem}>
          <Link to={RoutePaths.Company}>
            <span className={classes.itemIcon}>
              <i className="fad fa-building" />
            </span>
            <ListItemText primary="Company" disableTypography />
          </Link>
        </ListItem>
        <ListItem button className={classes.listItem}>
          <Link to={RoutePaths.Contact}>
            <span className={classes.itemIcon}>
              <i className="fad fa-comments" />
            </span>
            <ListItemText primary="Contact" disableTypography />
          </Link>
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Grid container spacing={0} className={classes.gridContainer}>
            {/* Mobile Toolbar */}
            <Hidden mdUp>
              <Grid item xs={8} sm={5}>
                <Link to="/">
                  <img
                    src={logo}
                    className={classes.logo}
                    alt="Quantum Protection Group"
                  />
                </Link>
              </Grid>
              <Grid item xs={2} sm={1}>
                <Box
                  className={classes.menuIconContainer}
                  onClick={toggleDrawer()}
                >
                  <i className={`${classes.icon} fal fa-bars`} />
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={open} onClose={toggleDrawer()}>
        {list()}
      </Drawer>
    </>
  );
}

export default MobileToolbar;

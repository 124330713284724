import React from 'react';
import {
  Toolbar,
  AppBar,
  Grid,
  Container,
  Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import MenuItems from './MenuItems';
import logo from '../assets/quantum-logo-orange-white.svg';
import MobileToolbar from './MobileToolbar';
import QuantumColors from '../data/QuantumColors';

const useStyles = makeStyles({
  appBar: {
    backgroundColor: QuantumColors.darkGray,
    boxShadow: 'none',
    borderBottom: '1px solid #1C1815',
    position: 'relative',
  },
  toolbar: {
    margin: '0 auto',
    padding: '0',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
  },
  gridContainer: {
    justifyContent: 'space-between',
  },
  logo: {
    textDecoration: 'none',
    height: '54px',
    padding: '5px 0 5px 10px',
  },
  menuIconContainer: {
    height: '100%',
    textAlign: 'center',
  },
  icon: {
    color: '#333',
    fontSize: '40px',
    height: '100%',
  },
});

function Header(): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Hidden mdUp>
        <MobileToolbar />
      </Hidden>

      <Hidden smDown>
        <AppBar position="static" className={classes.appBar}>
          <Container>
            <Toolbar className={classes.toolbar}>
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item md={3}>
                  <Link to="/">
                    <img
                      src={logo}
                      className={classes.logo}
                      alt="Quantum Protection Group"
                    />
                  </Link>
                </Grid>
                <Grid item md={7}>
                  <MenuItems />
                </Grid>
              </Grid>
            </Toolbar>
          </Container>
        </AppBar>
      </Hidden>
    </>
  );
}

export default Header;

import React from 'react';
import {
  Container,
  Grid,
  Hidden,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ServicesGrid from '../components/ServicesGrid';
import QuantumEpicSection from '../components/QuantumEpicSection';
import QuantumColors from '../data/QuantumColors';

const useStyles = makeStyles({
  paper: {
    marginTop: 20,
    padding: 20,
    boxSizing: 'border-box',
    overflow: 'hidden',

    '& h1': {
      textAlign: 'center',
      fontFamily: 'Ailerons',
      fontWeight: 'normal',
      fontSize: '48px',
      marginBlockStart: 0,
      marginBlockEnd: 0,

      '& span:first-child': {
        letterSpacing: '-10px',
      },
      '& span:last-child': {
        fontFamily: 'Roboto',
        fontSize: '.6em',
        verticalAlign: 'super',
      },
    },
    '& hr': {
      border: `1px solid ${QuantumColors.lightGray2}`,
    },
  },
  epicSection: {
    '& p': {
      textAlign: 'center',
      fontSize: '1.3em',
    },

    '& ul': {
      listStyle: 'none',
      fontSize: '1.2em',

      '& li:first-letter': {
        fontWeight: 'bold',
        fontSize: '1.1em',
        letterSpacing: '2px',
      },
    },

    '& span:first-letter': {
      fontWeight: 'bold',
      fontSize: '1.1em',
    },
  },
  textSection: {
    textAlign: 'center',
    color: QuantumColors.darkGray,

    '& p': {
      fontSize: '1.3em',
      paddingBottom: 15,
    },
  },
});

function Homepage(): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Container>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.epicSection}>
                  <h1>
                    Quantum&nbsp;
                    <span>
                      <wbr />
                      E.P.I.C.
                    </span>
                    <span>™</span>
                  </h1>
                  <Hidden xsDown>
                    <p>
                      Introducing our all-inclusive security and technology suite.
                      <br />
                      <strong>Quantum E.P.I.C.</strong>
                      {' '}
                      is&nbsp;
                      <span>Executive Protection</span>
                      ,&nbsp;
                      <span>Intelligence</span>
                      , &amp;&nbsp;
                      <span>Control</span>
                      , plus optional Fire services.
                    </p>
                  </Hidden>
                  <Hidden smUp>
                    <p>
                      <ul>
                        <li>
                          Executive
                        </li>
                        <li>
                          Protection,
                        </li>
                        <li>
                          Intelligence,
                        </li>
                        <li>
                          Command
                        </li>
                      </ul>
                    </p>
                  </Hidden>
                  <QuantumEpicSection />
                  <hr />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.textSection}>
              <h1>Quantum Protection Group</h1>
              <h3>Security + Technology</h3>
              <p>
                Our objective is to bring the traditional aspects and scope of
                providing concierge level security services, with the introduction
                of next level software engineering and integrating and interfacing,
                technology to  form a cohesive, whole new concept of security to
                our clients.
              </p>
              <hr />
            </Grid>
            <Grid item xs={12}>
              <h1>Services</h1>
              <ServicesGrid />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
}

export default Homepage;

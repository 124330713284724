import React from 'react';
import {
  Container,
  Grid,
  Hidden,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IServicesPageData } from '../data/ServicesPagesData';

const useStyles = makeStyles({
  paper: {
    marginTop: 20,
    padding: 20,
    boxSizing: 'border-box',
    overflow: 'hidden',

    '& h3': {
      fontWeight: 'normal',
    },
  },
  section: {
    paddingBottom: 20,

    '& p': {
      fontSize: '1.3em',
      // marginBlockStart: 0,
      lineHeight: '130%',
    },
    '& img': {
      maxWidth: '100%',
    },
  },
  left: {
    '& p': {
      paddingLeft: 20,
    },
  },
  right: {
    '& p': {
      paddingRight: 20,
    },
  },
  text: {
    verticalAlign: 'middle',
  },
});

function ServicesPageTemplate1(pageData: IServicesPageData): JSX.Element {
  const classes = useStyles();
  type LayoutPattern = 'alternate' | 'leftOnly' | 'rightOnly';

  function makePageSections(
    paragraphs: JSX.Element[],
    images?: JSX.Element[],
    layoutPattern: LayoutPattern = 'alternate',
  ): JSX.Element[] {
    const sections: JSX.Element[] = [];

    paragraphs.forEach((paragraph: JSX.Element, index: number): void => {
      const rightSection: JSX.Element = (
        <>
          <Grid
            container
            spacing={3}
            className={`${classes.section} ${classes.right}`}
          >
            <Grid item xs={12} md={6} className={classes.text}>
              <hr />
              {paragraph}
              <hr />
            </Grid>
            <Grid item xs={12} md={6}>
              {images && images[index]}
            </Grid>
          </Grid>
        </>
      );

      const leftSection: JSX.Element = (
        <>
          <Grid
            container
            spacing={3}
            className={`${classes.section} ${classes.left}`}
          >
            <Grid item xs={12} md={6}>
              {images && images[index]}
            </Grid>
            <Grid item xs={12} md={6} className={classes.text}>
              <hr />
              {paragraph}
              <hr />
            </Grid>
          </Grid>
        </>
      );

      if (layoutPattern === 'alternate') {
        sections.push(index % 2 ? rightSection : leftSection);
      }
      if (layoutPattern === 'leftOnly') {
        sections.push(leftSection);
      }
      if (layoutPattern === 'rightOnly') {
        sections.push(rightSection);
      }
    });

    return sections;
  }

  return (
    <>
      <Container>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h1>{pageData?.title}</h1>
              <h3>{pageData?.titleCaption}</h3>
            </Grid>
          </Grid>
          <Hidden smDown>
            {makePageSections(pageData.paragraphs, pageData.images)}
          </Hidden>
          <Hidden mdUp>
            {makePageSections(pageData.paragraphs, pageData.images, 'leftOnly')}
          </Hidden>
        </Paper>
      </Container>
    </>
  );
}

export default ServicesPageTemplate1;

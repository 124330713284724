import { isValidNumber } from 'libphonenumber-js';
import * as EmailValidator from 'email-validator';

export interface IContactFormValidationRequest {
  nameFieldValue: string;
  messageFieldValue: string;
  selectedResponseType: string;
  responseFieldValue: string;
}

export interface IContactFormValidationResponse {
  nameField: boolean;
  messageField: boolean;
  responseField: boolean;
}

function isNotEmpty(name: string): boolean {
  return name.length > 1;
}

function isResponseFieldValid(type: string, value: string): boolean {
  if (type === 'email') {
    return EmailValidator.validate(value);
  }
  if (type === 'phone' || type === 'sms') {
    return isValidNumber(value, 'US');
  }

  return false;
}

function ContactFormValidation(
  formData: IContactFormValidationRequest,
): IContactFormValidationResponse {
  const {
    nameFieldValue: name,
    messageFieldValue: message,
    selectedResponseType: responseType,
    responseFieldValue: responseField,
  } = formData;

  return {
    nameField: isNotEmpty(name),
    messageField: isNotEmpty(message),
    responseField: isResponseFieldValid(responseType, responseField),
  };
}

export default ContactFormValidation;

import React from 'react';
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Box,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { servicesMenuItemsData } from '../data/ServicesMenuData';
import QuantumColors from '../data/QuantumColors';

const useStyles = makeStyles({
  serviceGrid: {
    justifyContent: 'space-between',
    alignItems: 'stretch',
    marginTop: 10,
  },
  serviceCard: {
    color: QuantumColors.lightGray1,
    backgroundColor: QuantumColors.darkGray,
    textAlign: 'center',
    cursor: 'pointer',
    height: '100%',

    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },

    '& button': {
      height: '100%',

      '&:hover span': {
        color: QuantumColors.pumpkin,
        '--fa-primary-color': QuantumColors.pumpkin,
        '--fa-secondary-color': QuantumColors.lightGray1,
      },
    },

    '& span': {
      display: 'block',
      fontSize: '30px',
    },

    '& div': {
      fontSize: '30px',
      fontFamily: 'Ailerons',
      textTransform: 'uppercase',
    },

    '& p': {
      fontFamily: 'Roboto',
      fontSize: '18px',
      textAlign: 'center',
      minHeight: '85px',
      padding: '0 20px',
      textTransform: 'none',
    },
  },
  cardMediaImage: {
    width: '100%',
    maxHeight: '200px',
    marginBottom: '10px',
  },
  cardContent: {
    padding: 0,
  },
});

function ServicesGrid(): JSX.Element {
  const classes = useStyles();
  const serviceGrid: JSX.Element[] = [];

  servicesMenuItemsData.forEach((menuItem) => {
    if (menuItem.showInGrid) {
      serviceGrid.push(
        <Grid item xs={12} md={6} lg={4} key={`service-grid-${menuItem.name}`}>
          <Card className={classes.serviceCard}>
            <Link to={menuItem.path}>
              <CardActionArea>
                <CardContent className={classes.cardContent}>
                  <CardMedia
                    component="img"
                    alt={`Picture of ${menuItem.name}`}
                    title={menuItem.name}
                    image={menuItem.image}
                    className={classes.cardMediaImage}
                  />
                  <span>
                    {menuItem.icon}
                  </span>
                  <Box>
                    {menuItem.name}
                  </Box>
                  <p>
                    {menuItem.description}
                  </p>
                </CardContent>
              </CardActionArea>
            </Link>
          </Card>
        </Grid>,
      );
    }
  });

  return (
    <>
      <Grid container spacing={3} className={classes.serviceGrid}>
        {serviceGrid}
      </Grid>
    </>
  );
}

export default ServicesGrid;

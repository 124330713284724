import React from 'react';
import {
  Box,
  Container,
  Grid,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ContactForm from '../components/ContactForm';
import QuantumColors from '../data/QuantumColors';
import CompanyData from '../data/CompanyData';

const useStyles = makeStyles({
  paper: {
    marginTop: 20,
    padding: 20,
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  gridContainer: {
    marginBottom: 20,
    justifyContent: 'center',
    // overflow: 'hidden',
    // maxWidth: '100%',

    '& h1': {
      fontFamily: 'Ailerons',
      textAlign: 'center',
      color: QuantumColors.darkGray,
      fontSize: '40px',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      paddingBottom: 10,
    },
  },
  phoneNumber: {
    textAlign: 'center',
    fontSize: '2em',
    color: QuantumColors.darkGray,

    '& svg': {
      color: QuantumColors.darkGray,

      '&:first-child': {
        marginRight: 15,
      },
    },

    '& p': {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      marginTop: 10,
    },

    '& a': {
      textDecoration: 'none',
      color: QuantumColors.pumpkin,
    },
  },
});

function Contact(): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Container>
        <Paper className={classes.paper}>
          <Grid
            container
            spacing={3}
            className={classes.gridContainer}
          >
            <Grid item xs={12}>
              <h1>Contact</h1>
            </Grid>
            <Grid item xs={12} className={classes.phoneNumber}>
              <i className="fal fa-phone" />
              <i className="fal fa-sms" />
              <p>
                Call or text us:
                <br />
                <a href={`tel:${CompanyData.E164PhoneNumber}`}>
                  {CompanyData.displayPhoneNumber}
                </a>
              </p>

            </Grid>
            <Grid item xs={12} md={5}>
              <Box className={classes.phoneNumber}>
                <i className="fal fa-envelope" />
                <p>
                  Send us an email:
                </p>
              </Box>
              <ContactForm />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
}

export default Contact;

import React, {
  Dispatch,
  SetStateAction,
} from 'react';
import {
  Container,
  makeStyles,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
} from '@material-ui/core';

const useStyles = makeStyles({
  login: {
    textAlign: 'center',
  },
  button: {
    display: 'inline-block',
    marginTop: 20,
    fontSize: '1.3em',
  },
});

interface IPreviewLoginProps {
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
}
interface State {
  password: string;
  showPassword: boolean;
}

function PreviewLogin({ setLoggedIn }: IPreviewLoginProps): JSX.Element {
  const classes = useStyles();
  const password: string[] = ['qpg', 'polaris'];
  const [values, setValues] = React.useState<State>({
    password: '',
    showPassword: false,
  });

  function correctPassword(inputPassword: string): boolean {
    const index: number = password.indexOf(inputPassword.toLowerCase());

    return index >= 0;
  }

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
    setLoggedIn(prop === 'password' && correctPassword(event.target.value));
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <Container className={classes.login}>
        <h1> Developer Preview </h1>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            autoFocus
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword && (
                    <span><i className="fad fa-eye" /></span>
                  )}
                  {!values.showPassword && (
                    <span><i className="fad fa-eye-slash" /></span>
                  )}
                </IconButton>
              </InputAdornment>
            )}
            labelWidth={70}
          />
        </FormControl>
      </Container>
    </>
  );
}

export default PreviewLogin;

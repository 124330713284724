/* eslint-disable react/jsx-key */
import React from 'react';
import { Hidden } from '@material-ui/core';
import PageNames from './PageNames';
import {
  IMenuItem,
  servicesMenuItemsData,
} from './ServicesMenuData';
import polarisFire from '../assets/polaris-quad-fire-rescue.jpg';
import polarisFireNight from '../assets/polaris-fire-night.jpg';
import wildThing from '../assets/heiman-wild-thing.png';

export interface IServicesPageData {
  name: string,
  title: string,
  titleCaption?: string,
  paragraphs: JSX.Element[],
  images?: JSX.Element[],
}

function getMenuDescription(pageName: PageNames): string | undefined {
  return servicesMenuItemsData.find(
    (item: IMenuItem): boolean => item.name === pageName,
  )?.description;
}

const ServicesPagesData: IServicesPageData[] = [
  {
    name: PageNames.Epic,
    title: PageNames.Epic,
    titleCaption: getMenuDescription(PageNames.Epic),
    paragraphs: [
      <>
        <p>Introducing Quantum E.P.I.C.™</p>
        <Hidden xsDown>
          <p>
            <b>E</b>
            xecutive&nbsp;
            <b>P</b>
            rotection,&nbsp;
            <b>I</b>
            ntelligence,&nbsp;
            <b>C</b>
            ommand
          </p>
        </Hidden>
        <Hidden smUp>
          <p>
            <b>E</b>
            xecutive
            <br />
            <b>P</b>
            rotection,
            <br />
            <b>I</b>
            ntelligence,
            <br />
            <b>C</b>
            ommand
          </p>
        </Hidden>
        <p>
          EPIC is our holistic approach to providing Concierge Protection to your portfolio.
          We hire only the best, prior law enforcement and military professionals, who have
          been subjected to performing under stress. We provide elite protection and design
          you a safety and security plan tailored to your needs.
        </p>
      </>,
      <>
        <p>
          The Quantum Command app lets you completely manage your security detail
          from your computer or mobile device. Track the position of your agents
          and issue orders to them, audit agent licensing and credentials, manage
          billing, schedules, missions and more!
        </p>
      </>,
      <>
        <p>
          And finally our fire suppression services provide our clients with peace of mind with
          our on-site fire services vehicles that can help mitigate loss until fire services
          arrive on scene. Quantum E.P.I.C... Giving you peace of mind, while pairing with your
          daily routine and securing your portfolio...
        </p>
      </>,
    ],
    images: [
      <img
        src="https://image.shutterstock.com/image-photo/bodyguard-suit-sunglasses-female-vip-600w-1213881061.jpg"
        alt={PageNames.Epic}
      />,
      <img
        src="https://image.shutterstock.com/image-photo/on-table-laptop-showing-world-600w-1243021126.jpg"
        alt={PageNames.Epic}
      />,
      <img
        src={polarisFireNight}
        alt={PageNames.Epic}
      />,
    ],
  },
  {
    name: PageNames.EP,
    title: 'Executive & Estate Protection',
    titleCaption: getMenuDescription(PageNames.EP),
    paragraphs: [
      <>
        <p>
          Many of our clients require a low visibility security presence, in the form of a plain
          clothed security professional, in the form of an Executive Protection Agent and or
          Residential Security Team Agent. We have handled countless security details.
        </p>
      </>,
      <>
        <p>
          <ul>
            <li>
              We hire only fully vetted former law enforcement or military personnel.
            </li>
            <li>
              Highly trained and skilled security protection professionals for whatever the
              client needs may require.
            </li>
            <li>
              Our Agents are former law enforcement and Military professionals, who have provided
              protection to many high profile assets, including business, sports, and celebrity
              personalities.
            </li>
            <li>
              All of our Agents at a minimum meet all the required training and skills that is a
              prerequisite of sworn Law Enforcement Personnel.
            </li>
            <li>
              You will find that our deployed agents are tactical savvy, yet client friendly, and
              above all else, always prepared for whatever situation may arise.
            </li>
          </ul>
        </p>
      </>,
    ],
    images: [
      <img
        src="https://image.shutterstock.com/image-photo/personal-driver-waiting-boss-on-600w-1318043381.jpg"
        alt={PageNames.Epic}
      />,
      <img
        src="https://image.shutterstock.com/image-photo/security-guard-600w-263827376.jpg"
        alt={PageNames.Epic}
      />,
    ],
  },
  {
    name: PageNames.Intelligence,
    title: 'Enhanced Intelligence Analysis',
    titleCaption: getMenuDescription(PageNames.Intelligence),
    paragraphs: [
      <>
        <p>
          Whether you&apos;re traveling abroad or just across the bay, don&apos;t
          go blindly into the night. These are volatile, disquieting times and
          one wrong turn can put you into an undesirable situation. Quantum
          Intelligence Analysts can provide the foresight you need to navigate
          your journey.
        </p>
      </>,
    ],
    images: [
      <img
        src="https://image.shutterstock.com/image-photo/on-table-laptop-showing-world-600w-1243021126.jpg"
        alt={PageNames.Intelligence}
      />,
    ],
  },
  {
    name: PageNames.Command,
    title: `${PageNames.Command}™`,
    titleCaption: getMenuDescription(PageNames.Command),
    paragraphs: [
      <>
        <p>
          The Quantum Command mobile and desktop app lets you completely manage
          your security detail from your computer or mobile device. Track the
          position of your agents and issue orders to them, audit agent licensing
          and credentials, manage billing, schedules, missions and more!
        </p>
      </>,
    ],
    images: [
      <img
        src="https://image.shutterstock.com/image-photo/closeup-image-male-hands-using-600w-594829253.jpg"
        alt={PageNames.Command}
      />,
    ],
  },
  {
    name: PageNames.Fire,
    title: PageNames.Fire,
    titleCaption: getMenuDescription(PageNames.Fire),
    paragraphs: [
      <>
        <p>
          We have recognized one of the risks of living in California, of the yearly wildfires.
          Our Clients and Society as a whole often live in these high risk fire areas. We have
          been able to successfully utilize low profile ATV and Small Pickup Fire Suppression
          Units to protect a client&apos;s most valuable asset. Their family and their homes, during
          these unfortunate fire disasters.
        </p>
        <p>
          This service if often combined with our Residential Security Teams, in areas that
          wildfires are a threat. Our Residential Security Team Agents are cross trained to
          react should a wildfire disaster occur.
        </p>
      </>,
      <>
        <p>
          Benefits:
          <ul>
            <li>
              Proactive approach to help contain or quell a wildfire threat.
            </li>
            <li>
              Help mitigate property loss in the event of a fire
            </li>
            <li>
              Help secure and preserve a client&apos;s most precious commodity, their family
              and residence.
            </li>
            <li>
              Possibly reduce insurance costs.
            </li>
          </ul>
        </p>
      </>,
    ],
    images: [
      <img
        src={wildThing}
        alt={PageNames.Fire}
      />,
      <img
        src={polarisFire}
        alt={PageNames.Fire}
      />,
    ],
  },
  {
    name: PageNames.Consulting,
    title: PageNames.Consulting,
    titleCaption: getMenuDescription(PageNames.Consulting),
    paragraphs: [
      <>
        <p>
          Changing times have required private citizens, business and industries to
          pay close attention to security within their own parameters.

          At no other time in recent history has there been a need for the private
          individual, business and industry, to be more worried with security issues.
        </p>
        <p>
          We offer consultations by professionals that conduct in depth assessments and surveys so
          both private individuals and businesses will know exactly where security holes are present
          and where improvement is needed to limit their exposure to such threats.
        </p>
      </>,

      <>
        <p>
          In today&apos;s global environment, it is imperative that nobody takes these threats
          lightly. It is essential that you do not allow your personal or corporate assets
          become a victim of these threats.
        </p>
        <p>
          Security Measures Include:
          <ul>
            <li>
              Potential Client - Employee Sabotage
            </li>
            <li>
              Theft and Disruption
            </li>
            <li>
              Cyber Crime
            </li>
            <li>
              Terrorism
            </li>
            <li>
              Workplace Employee Violence
            </li>
          </ul>
          Any one of the afore mentioned could bring any individual or business to face a harsh
          business and financial reality.
        </p>
        <p>
          Together we will install the most fail safe counter measures necessary to protect
          your business portfolio, your employees, your business, your assets, and your bottom line.
        </p>
      </>,
    ],
    images: [
      <img
        src="https://image.shutterstock.com/image-photo/back-view-businesswoman-speak-using-600w-1689337993.jpg"
        alt={PageNames.Consulting}
      />,
      <img
        src="https://image.shutterstock.com/image-photo/business-team-hands-working-plan-600w-368205530.jpg"
        alt={PageNames.Consulting}
      />,
    ],
  },
  {
    name: PageNames.EventSecurity,
    title: PageNames.EventSecurity,
    titleCaption: getMenuDescription(PageNames.EventSecurity),
    paragraphs: [
      <>
        <p>
          We have years of event security experience. From County Fair Events,
          to events at the annual Burning Man Event, in the Blackrock Desert
          and Laguna Seca Raceway. No event is too big or small for our expert
          personnel. We offer a unique service for Public Events that require
          a public safety presence and event management.
        </p>
      </>,
      <>
        <p>
          We Provide:
          <ul>
            <li>
              Pre-event inspection and safety analysis, and post incident investigations.
            </li>
            <li>
              Full incident documentation of general liability to be utilized by the private or
              corporate business entity, in workers compensation, employee fraud, and
              governmental regulatory claims.
            </li>
            <li>
              Both EMS and Physician services for on scene customer medical services.
            </li>
            <li>
              Immediate on site treatment and clearing of minor injuries and illnesses.
            </li>
            <li>
              Preparations for major injury or illness in advance of the arrival of 911 resources.
            </li>
          </ul>
        </p>
      </>,
      <>
        <p>
          The positive net effect of providing these services also acts as
          incident documentation and liability risk management. In addition to
          emergency fire and medical teams for large scale events, we offer
          coordination, logistics, and sometimes physical fences, retainers,
          and crowd control.
        </p>
      </>,
    ],
    images: [
      <img
        src="https://image.shutterstock.com/image-photo/harlingen-netherlands-august-5-2018-600w-1149788618.jpg"
        alt={PageNames.EventSecurity}
      />,
      <img
        src="https://image.shutterstock.com/image-photo/security-hand-fastens-red-tape-600w-1317583364.jpg"
        alt={PageNames.EventSecurity}
      />,
      <img
        src="https://image.shutterstock.com/image-photo/crowds-enjoying-themselves-outdoor-music-600w-184911035.jpg"
        alt={PageNames.EventSecurity}
      />,
    ],
  },
  {
    name: PageNames.Investigations,
    title: PageNames.Investigations,
    titleCaption: getMenuDescription(PageNames.Investigations),
    paragraphs: [
      <>
        <p>
          As a premier investigation agency, we offer capabilities far beyond the ordinary,
          including a full range of professional investigative services. We hire experienced
          former law enforcement personnel to help clients investigate a cause of concern.
          Our expertise affords the clients peace of mind, knowing that our investigators
          have experience in the most complex of issues.
        </p>
      </>,
      <>
        <p>
          Our Investigation specialists investigate an assortment of cases including:
          <ul>
            <li>
              Private and Public Sector Investigations
            </li>
            <li>
              Criminal and Civil Investigations
            </li>
            <li>
              Cyber Crime
            </li>
            <li>
              Loss Prevention
            </li>
            <li>
              Surveillance
            </li>
            <li>
              Skip Tracing
            </li>
            <li>
              Private entities, corporations, general counsels, and their affiliated
              legal firms require prompt and precise information.
            </li>
          </ul>
        </p>
      </>,
      <>
        <p>
          Uncovering and minimizing the risks of internal fraudulent activity, vendor
          misconduct, sexual harassment, discrimination, and employee negligence are
          among the primary concerns of corporate managers.
        </p>
        <p>
          Our team conducts discrete investigations in conjunction with your in house
          security team, legal counsel and law enforcement, when necessary to pursue
          recovery, prosecution, or other successful case resolutions.
        </p>
        <p>
          We will work with you to recommend procedures for reducing private and
          corporate business losses and liabilities.
        </p>
      </>,
    ],
    images: [
      <img
        src="https://image.shutterstock.com/image-photo/private-investigator-on-stakeout-photographing-600w-283278590.jpg"
        alt={PageNames.Intelligence}
      />,
      <img
        src="https://image.shutterstock.com/image-photo/closeup-human-hand-filling-criminal-600w-1588824106.jpg"
        alt={PageNames.Intelligence}
      />,
      <img
        src="https://image.shutterstock.com/image-photo/laptop-computer-magnifying-glass-concept-600w-545638276.jpg"
        alt={PageNames.Intelligence}
      />,
    ],
  },
  {
    name: PageNames.Transport,
    title: PageNames.Transport,
    titleCaption: getMenuDescription(PageNames.Transport),
    paragraphs: [
      <>
        <p>
          Do you have highly valuable merchandise, or artifacts that need a
          security escort during transportation? If so, Quantum Protection Group,
          offers discrete covert agents that travel with or follow your
          transportation unit to be sure your delivery arrives as promised.
        </p>
        <p>
          Our agents can either be armed or unarmed for your assignment.
          Additionally, we can handle coordination for the transportation of
          merchandise itself, from pickup to arrival at its destination.
        </p>
        <p>
          Contact us for your secure High Value Transport needs.
        </p>
      </>,
    ],
    images: [
      <img
        src="https://image.shutterstock.com/image-photo/concert-boxes-equipment-organizing-concerts-600w-1498699193.jpg"
        alt={PageNames.Transport}
      />,
      <img
        src="https://image.shutterstock.com/image-illustration/truck-runs-on-highway-speed-600w-701843044.jpg"
        alt={PageNames.Transport}
      />,
    ],
  },
  {
    name: PageNames.UniformedPatrol,
    title: PageNames.UniformedPatrol,
    titleCaption: getMenuDescription(PageNames.UniformedPatrol),
    paragraphs: [
      <>
        <p>
          If you have a business that needs a security presence to protect your assets,
          we can fill that need.
        </p>
        <p>
          We employ fully trained Security Patrol Officers:
          <ul>
            <li>
              They have met all the requirements of our rigorous background checks
            </li>
            <li>
              They meet all the BSIS Standards of Training.
            </li>
            <li>
              All uniformed Security Officers are First Aid / CPR / AED / BLS Certified.
            </li>
            <li>
              Our Patrol Officers are Armed or Unarmed at the client’s request.
            </li>
            <li>
              Clients can rest assured our personnel are the best trained, and most
              professional officers in the security industry.
            </li>
          </ul>
        </p>
      </>,
    ],
    images: [
      <img
        src="https://image.shutterstock.com/image-photo/security-guard-using-walkie-talkie-600w-1726919005.jpg"
        alt={PageNames.UniformedPatrol}
      />,
      <img
        src="https://image.shutterstock.com/image-photo/security-guards-monitoring-modern-cctv-600w-1240832179.jpg"
        alt={PageNames.UniformedPatrol}
      />,
      <img
        src="https://image.shutterstock.com/image-photo/male-security-guard-using-portable-600w-1114995335.jpg"
        alt={PageNames.UniformedPatrol}
      />,
      <img
        src="https://image.shutterstock.com/image-photo/portrait-young-male-security-guard-600w-302066921.jpg"
        alt={PageNames.UniformedPatrol}
      />,
      <img
        src="https://image.shutterstock.com/image-photo/young-male-security-guard-black-600w-456824191.jpg"
        alt={PageNames.UniformedPatrol}
      />,
      <img
        src="https://image.shutterstock.com/image-photo/young-male-security-guard-black-600w-460673191.jpg"
        alt={PageNames.UniformedPatrol}
      />,
    ],
  },
  {
    name: PageNames.B2B,
    title: PageNames.B2B,
    titleCaption: getMenuDescription(PageNames.B2B),
    paragraphs: [
      <>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </>,
    ],
    images: [
      <img
        src="https://image.shutterstock.com/image-photo/business-people-shaking-hands-finishing-600w-420967090.jpg"
        alt={PageNames.B2B}
      />,
      <img
        src="https://image.shutterstock.com/image-photo/businessman-touching-global-network-data-600w-566877226.jpg"
        alt={PageNames.B2B}
      />,
      <img
        src="https://image.shutterstock.com/image-photo/global-business-concept-silhouette-people-600w-785054566.jpg"
        alt={PageNames.B2B}
      />,
      <img
        src="https://image.shutterstock.com/image-photo/double-exposure-business-handshake-successful-600w-306860177.jpg"
        alt={PageNames.B2B}
      />,

    ],
  },
];

export function getPageData(pageName: PageNames): IServicesPageData | undefined {
  return ServicesPagesData
    .find((item: IServicesPageData): boolean => item.name === pageName);
}

export default ServicesPagesData;

import React, {
  useLayoutEffect,
  useRef,
} from 'react';
import {
  Grid,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import epPoster from '../assets/epic-section/epPoster.jpg';
import intelligencePoster from '../assets/epic-section/intelligencePoster.jpg';
import commandPoster from '../assets/epic-section/command.jpg';
import firePoster from '../assets/epic-section/firePoster.jpg';
import QuantumColors from '../data/QuantumColors';

const useStyles = makeStyles({
  epicSection: {
    margin: '20px 0 50px 0',
    width: '100%',
  },
  gridItem: {
    height: 600,
  },
  pane: {
    backgroundColor: QuantumColors.black,
    width: '100%',
    height: 600,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  column: {
    width: '25%',
    height: 600,
    transition: 'all 300ms',
    borderRight: `3px solid ${QuantumColors.lightGray3}`,
    borderLeft: `3px solid ${QuantumColors.lightGray2}`,
    position: 'relative',
    display: 'inline',
    overflow: 'hidden',
    '&:hover': {
      width: '100%',
    },
  },
  epColumn: {
    zIndex: 4,
  },
  intelligenceColumn: {
    zIndex: 3,
  },
  commandColumn: {
    zIndex: 2,
  },
  fireColumn: {
    zIndex: 1,
  },
  activeColumn: {
    boxShadow: `0 0 10px ${QuantumColors.black}`,
  },
  inactiveColumn: {
    width: 150,
    filter: 'grayscale(80%) brightness(50%)',
  },
  columnImage: {
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  columnVideo: {
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  textOverlay: {
    width: '25%',
    height: '100%',
    zIndex: 5,
    position: 'relative',
    boxSizing: 'border-box',
    padding: '10px 40px',
    // display: 'none',
    // opacity: 0,

    '& h2': {
      fontSize: '3em',
      color: '#fff',
      textShadow: '3px 3px black',
    },
  },
  showTextOverlay: {
    // display: 'block',
    opacity: 1,
    transition: 'opacity, 600ms',
  },
});

function QuantumEpicSection(): JSX.Element {
  const classes = useStyles();

  const epRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const intelligenceRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const commandRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const fireRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const columnRefArray: React.RefObject<HTMLDivElement>[] = [
    epRef,
    intelligenceRef,
    commandRef,
    fireRef,
  ];

  const epVideoRef: React.RefObject<HTMLVideoElement> = useRef<HTMLVideoElement>(null);
  const intelligenceVideoRef: React.RefObject<HTMLVideoElement> = useRef<HTMLVideoElement>(null);
  const commandVideoRef: React.RefObject<HTMLVideoElement> = useRef<HTMLVideoElement>(null);
  const fireVideoRef: React.RefObject<HTMLVideoElement> = useRef<HTMLVideoElement>(null);
  const videoRefArray: React.RefObject<HTMLVideoElement>[] = [
    epVideoRef,
    intelligenceVideoRef,
    commandVideoRef,
    fireVideoRef,
  ];
  const videoSrcArray: string[] = [
    'https://ak.picdn.net/shutterstock/videos/1019573983/preview/stock-footage-personal-driver-meeting-and-opening-car-door-for-lady-boss-bodyguard-duties.webm',
    'https://ak.picdn.net/shutterstock/videos/1056263531/preview/stock-footage-cctv-ai-facial-recognition-camera-zoom-in-recognizes-person-elevated-security-camera-surveillance.webm',
    'https://ak.picdn.net/shutterstock/videos/1018110292/preview/stock-footage-surveillance-system-tracking-person-s-phone-sim-card-tracking-spying-on-target-phone-tracking.webm',
    'https://ak.picdn.net/shutterstock/videos/1014058715/preview/stock-footage--firefighters-work-hard-to-contain-brush-fires-burning-out-of-control-during-the-thomas-fire.webm',
  ];

  const epOverlayRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const intelligenceOverlayRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const commandOverlayRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const fireOverlayRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const overlayRefArray: React.RefObject<HTMLDivElement>[] = [
    epOverlayRef,
    intelligenceOverlayRef,
    commandOverlayRef,
    fireOverlayRef,
  ];

  function columnEnter(
    event: MouseEvent, colRef: React.RefObject<HTMLDivElement>,
    vidRef: React.RefObject<HTMLVideoElement>,
    overlayRef: React.RefObject<HTMLDivElement>,
  ): void {
    const inactiveColumnRefs: React.RefObject<HTMLDivElement>[] = [...columnRefArray];
    inactiveColumnRefs.splice(columnRefArray.indexOf(colRef), 1);
    for (let i = 0; i < inactiveColumnRefs.length; i += 1) {
      inactiveColumnRefs[i].current?.classList.add(classes.inactiveColumn);
      // overlayRefArray[i].current?.classList.remove(classes.showTextOverlay);
    }

    colRef.current?.classList.add(classes.activeColumn);

    // show and play video
    if (vidRef && vidRef.current) {
      vidRef.current.src = videoSrcArray[columnRefArray.indexOf(colRef)];
      vidRef.current.preload = 'true';
      vidRef.current.autoplay = true;

      setTimeout((): void => {
        overlayRef?.current?.classList.add(classes.showTextOverlay);
      }, 300);
    }
  }

  function columnExit(
    event: MouseEvent,
    colRef: React.RefObject<HTMLDivElement>,
    vidRef: React.RefObject<HTMLVideoElement>,
    overlayRef: React.RefObject<HTMLDivElement>,
  ): void {
    for (let i = 0; i < columnRefArray.length; i += 1) {
      columnRefArray[i].current?.classList.remove(classes.inactiveColumn);
    }

    colRef.current?.classList.remove(classes.activeColumn);

    if (vidRef && vidRef.current) {
      vidRef.current.src = '';
    }
    overlayRef?.current?.classList.remove(classes.showTextOverlay);
  }

  useLayoutEffect((): void => {
    columnRefArray.forEach((colRef: React.RefObject<HTMLDivElement>, index) => {
      colRef.current?.addEventListener('mouseenter', (event: MouseEvent) => columnEnter(event, colRef, videoRefArray[index], overlayRefArray[index]));
      colRef.current?.addEventListener('mouseout', (event: MouseEvent) => columnExit(event, colRef, videoRefArray[index], overlayRefArray[index]));
    });
  });

  return (
    <>
      <Grid
        container
        spacing={0}
        className={classes.epicSection}
      >
        <Grid
          item
          xs={12}
          className={classes.gridItem}
        >
          <Paper
            elevation={0}
            className={classes.pane}
          >
            <div
              className={`${classes.column} ${classes.epColumn}`}
              ref={epRef}
            >
              <video
                muted
                loop
                poster={epPoster}
                className={classes.columnVideo}
                ref={epVideoRef}
              >
                <source />
              </video>
              <div className={classes.textOverlay} ref={epOverlayRef}>
                <h2>Executive Protection</h2>
              </div>
            </div>
            <div
              className={`${classes.column} ${classes.intelligenceColumn}`}
              ref={intelligenceRef}
            >
              <video
                muted
                loop
                poster={intelligencePoster}
                className={classes.columnVideo}
                ref={intelligenceVideoRef}
              >
                <source />
              </video>
              <div className={classes.textOverlay} ref={intelligenceOverlayRef}>
                <h2>Intelligence</h2>
              </div>
            </div>
            <div
              className={`${classes.column} ${classes.commandColumn}`}
              ref={commandRef}
            >
              <video
                muted
                loop
                poster={commandPoster}
                className={classes.columnVideo}
                ref={commandVideoRef}
              >
                <source />
              </video>
              <div className={classes.textOverlay} ref={commandOverlayRef}>
                <h2>Command</h2>
              </div>
            </div>
            <div
              className={`${classes.column} ${classes.fireColumn}`}
              ref={fireRef}
            >
              <video
                muted
                loop
                poster={firePoster}
                className={classes.columnVideo}
                ref={fireVideoRef}
              >
                <source />
              </video>
              <div className={classes.textOverlay} ref={fireOverlayRef}>
                <h2>Fire</h2>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default QuantumEpicSection;

import React from 'react';
import {
  ClickAwayListener,
  MenuItem,
  MenuList,
  Popper,
  Paper,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { servicesMenuItemsData } from '../data/ServicesMenuData';
import RoutePaths from '../data/RoutePaths';
import PageNames from '../data/PageNames';
import QuantumColors from '../data/QuantumColors';

const useStyles = makeStyles({
  list: {
    display: 'flex',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
  },
  listItem: {
    fontFamily: 'Ailerons',
    letterSpacing: '-2px',
  },
  listItemLink: {
    textDecoration: 'none',
    fontSize: '28px',
    color: '#D3CDC6',
    '&:hover': {
      color: QuantumColors.pumpkin,
      '--fa-primary-color': QuantumColors.pumpkin,
      '--fa-secondary-color': QuantumColors.lightGray1,
    },
  },
  childMenu: {
    backgroundColor: QuantumColors.darkGray,
    marginTop: '8px',
    borderRadius: '0',
    border: '2px solid #1C1815',
    borderTop: 'none',
  },
  childMenuItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  childMenuLink: {
    textDecoration: 'none',
    fontSize: '22px',
    color: '#D3CDC6',
    width: '100%',
    paddingLeft: '16px',
    paddingRight: '16px',
    '&:hover': {
      color: QuantumColors.pumpkin,
      '--fa-primary-color': QuantumColors.pumpkin,
      '--fa-secondary-color': QuantumColors.lightGray1,
    },
    '& span': {
      display: 'inline-block',
      width: '40px',
    },
  },
});

function MenuItems(): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLLIElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <MenuList className={classes.list}>
        <MenuItem
          className={`${classes.listItem} ${classes.listItemLink}`}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          Services
        </MenuItem>
        <MenuItem className={classes.listItem}>
          <Link to={RoutePaths.Covid19} className={classes.listItemLink}>
            {PageNames.Covid19}
          </Link>
        </MenuItem>
        <MenuItem className={classes.listItem}>
          <Link to={RoutePaths.Company} className={classes.listItemLink}>
            {PageNames.Company}
          </Link>
        </MenuItem>
        <MenuItem className={classes.listItem}>
          <Link to={RoutePaths.Contact} className={classes.listItemLink}>
            {PageNames.Contact}
          </Link>
        </MenuItem>
        <MenuItem className={classes.listItem}>
          <Link to={RoutePaths.Login} className={classes.listItemLink}>
            {PageNames.Login}
          </Link>
        </MenuItem>
      </MenuList>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        <Paper className={classes.childMenu}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
              { servicesMenuItemsData.map((item) => (
                <MenuItem
                  onClick={handleClose}
                  className={classes.childMenuItem}
                  key={item.name}
                >
                  <Link to={item.path} className={classes.childMenuLink}>
                    <span>{item.icon}</span>
                    {item.name}
                  </Link>
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
}

export default MenuItems;

import React from 'react';
import { Container } from '@material-ui/core';
import {
  getPageData,
  IServicesPageData,
} from '../../data/ServicesPagesData';
import PageNames from '../../data/PageNames';
import ServicesPageTemplate1 from '../../components/ServicesPageTemplate1';

function QuantumEPIC(): JSX.Element {
  const pageData: IServicesPageData | undefined = getPageData(PageNames.B2B);

  if (pageData === undefined) {
    return (
      <>
        <Container>
          <h1>{PageNames.B2B}</h1>
        </Container>
      </>
    );
  }

  return (
    <>
      <ServicesPageTemplate1
        name={pageData.name}
        title={pageData.title}
        titleCaption={pageData.titleCaption}
        paragraphs={pageData.paragraphs}
        images={pageData.images}
      />
    </>
  );
}

export default QuantumEPIC;

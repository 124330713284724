import React, {
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Fab,
  Grid,
  TextField,
} from '@material-ui/core';
import { AsYouType } from 'libphonenumber-js';
import QuantumColors from '../data/QuantumColors';
import ContactFormValidation, {
  IContactFormValidationRequest,
  IContactFormValidationResponse,
} from '../utils/ContactFormValidation';
import ContactFormSend from '../utils/ContactFormSend';

const useStyles = makeStyles({
  form: {
    width: '100%',
    marginTop: 20,

    '& label.Mui-focused': {
      color: QuantumColors.pumpkin,
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: QuantumColors.pumpkin,
    },

    '& .Mui-error': {
      color: QuantumColors.darkGray,
      backgroundColor: '#f99',
    },
    '& .Mui-error:after': {
      borderBottomColor: '#f00',
    },
  },
  formContainer: {
    backgroundColor: QuantumColors.darkGray,
    padding: 10,
  },
  textField: {
    backgroundColor: QuantumColors.lightGray1,

    '& input': {
      color: QuantumColors.darkGray,
    },
  },
  sendButton: {
    backgroundColor: QuantumColors.pumpkin,
    color: QuantumColors.darkGray,
    fontWeight: 'bold',
    fontSize: '20px',

    '&.MuiButtonBase-root:disabled': {
      backgroundColor: QuantumColors.darkGray2,
      cursor: 'not-allowed',
    },
  },
  responseOptions: {
    color: QuantumColors.lightGray1,
    justifyContent: 'space-between',

    '& .MuiGrid-item': {
      textAlign: 'center',
    },
    '& .MuiGrid-item:first-child': {
      textAlign: 'start',
    },
    '& .MuiGrid-item:last-child': {
      textAlign: 'end',
    },
  },
  responseFab: {
    fontSize: '1em',
    width: '100%',
    borderRadius: '0',
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: QuantumColors.darkGray2,
      color: QuantumColors.lightGray1,
    },

    '&:hover svg': {
      color: QuantumColors.pumpkin,
    },

    '& svg': {
      fontSize: '1.3em',
      paddingRight: '8px',
    },
  },
  selected: {
    backgroundColor: QuantumColors.darkGray2,
    color: QuantumColors.lightGray1,

    '& svg': {
      color: QuantumColors.pumpkin,
    },
  },
  statusMessage: {
    color: QuantumColors.lightGray1,
    fontWeight: 'bold',

    '& span': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  },
  errorMessage: {
    '& svg': {
      fontSize: '2.5em',
      paddingRight: 10,
      color: QuantumColors.errorRed,
    },
  },
  sendStatus: {
    '& svg': {
      display: 'block',
      fontSize: '2.5em',
      marginRight: 10,
      color: QuantumColors.pumpkin,
      '--fa-primary-color': QuantumColors.pumpkin,
      '--fa-secondary-color': QuantumColors.lightGray1,
    },
  },
});

type ResponseType = 'email' | 'phone' | 'sms';
type ResponseLabel = 'Email' | 'Phone' | 'SMS';
type ResponseInputType = 'email' | 'tel';
type MessageStatus = 'compose' | 'invalid' | 'sending' | 'sent' | 'failed';

function ContactForm(): JSX.Element {
  const classes = useStyles();

  // state variables
  const [
    nameFieldValue,
    setNameFieldValue,
  ]: [string, Dispatch<SetStateAction<string>>] = useState('');
  const [
    messageFieldValue,
    setMessageFieldValue,
  ]: [string, Dispatch<SetStateAction<string>>] = useState('');
  const [
    selectedResponseType,
    setSelectedResponseType,
  ]: [ResponseType, Dispatch<SetStateAction<ResponseType>>] = useState('email' as ResponseType);
  const [
    responseLabel,
    setResponseLabel,
  ]: [ResponseLabel, Dispatch<SetStateAction<ResponseLabel>>] = useState('Email' as ResponseLabel);
  const [
    responseInputType,
    setResponseInputType,
  ]: [ResponseInputType, Dispatch<SetStateAction<ResponseInputType>>] = useState('email' as ResponseInputType);
  const [
    emailFieldValue,
    setEmailFieldValue,
  ]: [string, Dispatch<SetStateAction<string>>] = useState('');
  const [
    phoneFieldValue,
    setPhoneFieldValue,
  ]: [string, Dispatch<SetStateAction<string>>] = useState('');
  const [
    responseFieldValue,
    setResponseFieldValue,
  ]: [string, Dispatch<SetStateAction<string>>] = useState('');
  const [
    nameFieldValid,
    setNameFieldValid,
  ]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(Boolean(true));
  const [
    messageFieldValid,
    setMessageFieldValid,
  ]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(Boolean(true));
  const [
    responseFieldValid,
    setResponseFieldValid,
  ]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(Boolean(true));
  const [
    messageStatus,
    setMessageStatus,
  ]: [MessageStatus, Dispatch<SetStateAction<MessageStatus>>] = useState('compose' as MessageStatus);

  // dom refs
  const emailButtonRef = useRef<HTMLButtonElement>(null);
  const phoneButtonRef = useRef<HTMLButtonElement>(null);
  const smsButtonRef = useRef<HTMLButtonElement>(null);
  const responseFieldRef = useRef<HTMLInputElement>(null);
  const buttonRefArray: React.RefObject<HTMLButtonElement>[] = [
    emailButtonRef,
    phoneButtonRef,
    smsButtonRef,
  ];

  function submit(): void {
    const validationRequestObject: IContactFormValidationRequest = {
      nameFieldValue,
      messageFieldValue,
      selectedResponseType,
      responseFieldValue,
    };

    const validationResponse: IContactFormValidationResponse = ContactFormValidation(
      validationRequestObject,
    );

    const {
      nameField,
      messageField,
      responseField,
    } = validationResponse;

    setNameFieldValid(nameField);
    setMessageFieldValid(messageField);
    setResponseFieldValid(responseField);

    if (nameField && messageField && responseField) {
      setMessageStatus('sending');
      ContactFormSend(validationRequestObject);
    } else {
      setMessageStatus('invalid');
    }
  }

  function responseTypeClicked(
    type: ResponseType,
    buttonRef: React.RefObject<HTMLButtonElement>,
  ): void {
    // capture what was typed by the user, this will prevent the field from
    // being wiped if the user clicks different response type buttons
    if (selectedResponseType === 'email') {
      setEmailFieldValue(responseFieldRef.current?.value || emailFieldValue);
    }
    if (selectedResponseType === 'phone' || selectedResponseType === 'sms') {
      setPhoneFieldValue(responseFieldRef.current?.getAttribute('value') || phoneFieldValue);
    }

    setSelectedResponseType(type);

    buttonRefArray.forEach((button: React.RefObject<HTMLButtonElement>): void => {
      button.current?.classList.remove(classes.selected);
    });

    buttonRef.current?.classList.add(classes.selected);

    if (type === 'email') {
      setResponseLabel('Email');
      setResponseInputType('email');
      setResponseFieldValue(emailFieldValue);
    }

    if (type === 'phone') {
      setResponseLabel('Phone');
      setResponseInputType('tel');
      setResponseFieldValue(phoneFieldValue);
    }

    if (type === 'sms') {
      setResponseLabel('SMS');
      setResponseInputType('tel');
      setResponseFieldValue(phoneFieldValue);
    }

    responseFieldRef.current?.focus();
  }

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <Grid container spacing={3} className={classes.formContainer}>
          <Grid item xs={12}>
            <TextField
              id="name"
              label="Name"
              value={nameFieldValue}
              onChange={(event): void => {
                setNameFieldValue(event.target.value);
              }}
              autoFocus
              type="text"
              fullWidth
              variant="filled"
              className={classes.textField}
              required
              error={!nameFieldValid}
              aria-required
              aria-label="Contact Form Name Field"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="message"
              multiline
              label="Message"
              value={messageFieldValue}
              onChange={(event): void => {
                setMessageFieldValue(event.target.value);
              }}
              type="text"
              fullWidth
              variant="filled"
              rows={4}
              className={classes.textField}
              required
              error={!messageFieldValid}
              aria-required
              aria-label="Contact Form Message Field"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} className={classes.responseOptions}>
              <Grid item xs={12}>
                How should we respond to you?
              </Grid>
              <Grid item xs={4}>
                <Fab
                  className={`${classes.responseFab} ${classes.selected}`}
                  variant="extended"
                  ref={emailButtonRef}
                  onClick={(): void => {
                    responseTypeClicked('email', emailButtonRef);
                  }}
                >
                  <i className="fal fa-envelope" />
                  Email
                </Fab>
              </Grid>
              <Grid item xs={4}>
                <Fab
                  className={classes.responseFab}
                  variant="extended"
                  ref={phoneButtonRef}
                  onClick={(): void => {
                    responseTypeClicked('phone', phoneButtonRef);
                  }}
                >
                  <i className="fal fa-phone" />
                  Phone
                </Fab>
              </Grid>
              <Grid item xs={4}>
                <Fab
                  className={classes.responseFab}
                  variant="extended"
                  ref={smsButtonRef}
                  onClick={(): void => {
                    responseTypeClicked('sms', smsButtonRef);
                  }}
                >
                  <i className="fal fa-sms" />
                  Text
                </Fab>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="responseField"
              label={responseLabel}
              type={responseInputType}
              value={responseFieldValue}
              onChange={(e): void => {
                if (responseLabel === 'Email') {
                  setEmailFieldValue(e.target.value);
                  setResponseFieldValue(e.target.value);
                } else {
                  setPhoneFieldValue(
                    new AsYouType('US')
                      .input(e.target.value),
                  );
                  setResponseFieldValue(
                    new AsYouType('US')
                      .input(e.target.value),
                  );
                }
              }}
              required
              error={!responseFieldValid}
              fullWidth
              variant="filled"
              className={classes.textField}
              aria-label={`Contact Form ${responseLabel} Field`}
              ref={responseFieldRef}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <Button
              variant="contained"
              className={classes.sendButton}
              aria-label="Contact Form Submit Button"
              onClick={submit}
              disabled={messageStatus === 'sending'}
            >
              Send
            </Button>
          </Grid>
          <Grid item xs={8} sm={9} className={classes.statusMessage}>
            {(messageStatus === 'invalid') && (
              <span className={classes.errorMessage}>
                <i className="fal fa-exclamation-square" />
                Please fix the errors above.
              </span>
            )}
            {(messageStatus === 'sending') && (
              <span className={classes.sendStatus}>
                <i className="fad fa-spinner fa-spin" />
                Sending message.
              </span>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default ContactForm;

import React from 'react';
import {
  Container,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import QuantumColors from '../data/QuantumColors';
import CompanyData from '../data/CompanyData';

const useStyles = makeStyles({
  footer: {
    color: QuantumColors.lightGray1,
    backgroundColor: QuantumColors.darkGray,
    borderTop: '1px solid #1C1815',
    padding: '30px 0',
    marginBottom: '-12px',
    marginTop: '20px',
    textAlign: 'center',

    '& a': {
      textDecoration: 'none',
      color: QuantumColors.pumpkin,
    },
  },
});

function Footer(): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <footer className={classes.footer}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              Call or Text:&nbsp;
              <a href={`tel:${CompanyData.E164PhoneNumber}`}>
                {CompanyData.displayPhoneNumber}
              </a>
            </Grid>
            <Grid item xs={12}>
              Copyright &copy;
              {new Date().getFullYear()}
              &nbsp;Quantum Protection Group
            </Grid>
          </Grid>
        </Container>
      </footer>
    </>
  );
}

export default Footer;

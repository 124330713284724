import React from 'react';
import ServicesPageTemplate1 from '../components/ServicesPageTemplate1';
import PageNames from '../data/PageNames';

const paragraphs: JSX.Element[] = [
  <>
    <p>
      We pride ourselves as being a Full Service, Concierge Security Firm and we
      apply a holistic approach to security.
    </p>
    <p>
      Our objective is to meet your security needs, whether you need a covert,
      overt, or uniformed guard presence. We strive to bring you peace of mind
      and seamlessly blend into your daily routine, as we protect your assets
      and portfolio.
    </p>
    <p>
      We bring together expert knowledge and experience from a comprehensive
      cross-section of security disciplines to help you assess your exposure to
      risk.
    </p>
  </>,
  <>
    <p>
      We hire only:
      <ul>
        <li>
          Law enforcement professionals
        </li>
        <li>
          Military professionals
        </li>
        <li>
          Individuals with criminology backgrounds
        </li>
      </ul>
    </p>
    <p>
      We can help you plan and deploy measured and sustainable security systems,
      processes and resources to comprehensively address the specific risks you
      face, wherever your business might take you.
    </p>
    <p>
      Attention to detail is our trademark, and our clients like our attentive
      and responsive approach that ensures our organization enjoys strong
      relationships with them.
    </p>
  </>,
];

/* eslint-disable react/jsx-key */
const images: JSX.Element[] = [
  <img
    src="https://image.shutterstock.com/image-photo/stylized-altered-generic-corporate-modern-260nw-225600154.jpg"
    alt={PageNames.Company}
    width="100%"
  />,
  <img
    src="https://image.shutterstock.com/image-photo/man-private-access-vip-services-260nw-1061620472.jpg"
    alt={PageNames.Company}
    width="100%"
  />,
  <img
    src="https://image.shutterstock.com/image-photo/closeup-empty-conference-room-before-260nw-144792703.jpg"
    alt={PageNames.Company}
    width="100%"
  />,
];

function Company(): JSX.Element {
  return (
    <>
      <ServicesPageTemplate1
        name={PageNames.Company}
        title={PageNames.Company}
        paragraphs={paragraphs}
        images={images}
      />
    </>
  );
}

export default Company;

import React from 'react';
import PageNames from './PageNames';
import RoutePaths from './RoutePaths';
import executiveProtectionImage from '../assets/grid/executive-protection.jpg';
import uniformedPatrolImage from '../assets/grid/uniformed-security.jpg';
import transportImage from '../assets/grid/high-value-transport.png';
import investigationsImage from '../assets/grid/investigations.jpg';
import intelligenceImage from '../assets/grid/intelligence.png';
import consultingImage from '../assets/grid/consulting.png';
import b2bImage from '../assets/grid/b2b.png';
import eventSecurityImage from '../assets/grid/event-security.png';
import fireSuppressionImage from '../assets/grid/fire.png';
import softwareDevImage from '../assets/grid/software.png';

export interface IMenuItem {
  name: string;
  path: string;
  icon?: JSX.Element;
  description?: string;
  image?: string;
  children?: IMenuItem[],
  showInGrid: boolean,
}

const faWeight = 'fad';
const servicesMenuItemsData: IMenuItem[] = [
  {
    name: PageNames.Epic,
    path: RoutePaths.Epic,
    icon: <i className="fad fa-atom fa-flip-horizontal" />,
    description: 'All inclusive security bundle.',
    showInGrid: false,
  },
  {
    name: PageNames.EP,
    path: RoutePaths.EP,
    icon: <i className={`${faWeight} fa-user-tie`} />,
    description: 'The ultimate in discretion and professionalism. Seasoned veterans ready to put their life on the line to defend you and your loved ones.',
    image: executiveProtectionImage,
    showInGrid: true,
  },
  {
    name: PageNames.Intelligence,
    path: RoutePaths.Intelligence,
    icon: <i className={`${faWeight} fa-search`} />,
    description: 'Analysis of real-time data to mitigate risk and create highly informed strategies.',
    image: intelligenceImage,
    showInGrid: true,
  },
  {
    name: PageNames.Command,
    path: RoutePaths.Command,
    icon: <i className={`${faWeight} fa-phone-laptop`} />,
    description: 'Real-time control of all on-site assets.',
    image: softwareDevImage,
    showInGrid: true,
  },
  {
    name: PageNames.Fire,
    path: RoutePaths.Fire,
    icon: <i className={`${faWeight} fa-fire-extinguisher`} />,
    description: 'Wildfires can move faster than fire departments are able to respond. On-site fire mitigation services can make all the difference in the world.',
    image: fireSuppressionImage,
    showInGrid: true,
  },
  {
    name: PageNames.Consulting,
    path: RoutePaths.Consulting,
    icon: <i className={`${faWeight} fa-mind-share`} />,
    description: 'Let our real-world experience guide you to making informed, correct decisions that fit your exact needs.',
    image: consultingImage,
    showInGrid: false,
  },
  {
    name: PageNames.EventSecurity,
    path: RoutePaths.EventSecurity,
    icon: <i className={`${faWeight} fa-guitar-electric`} />,
    description: 'From rock concerts to sporting events to black tie soirées, we will discretely ensure the safety of your guests.',
    image: eventSecurityImage,
    showInGrid: true,
  },
  {
    name: PageNames.Investigations,
    path: RoutePaths.Investigations,
    icon: <i className={`${faWeight} fa-user-secret`} />,
    description: ' Never settle for guesses or assumptions. Know who you\'re dealing with and get the hard facts.',
    image: investigationsImage,
    showInGrid: true,
  },
  {
    name: PageNames.Transport,
    path: RoutePaths.Transport,
    icon: <i className={`${faWeight} fa-truck-moving`} />,
    description: 'Valuable cargo hand-delivered strategically and securely.',
    image: transportImage,
    showInGrid: true,
  },
  {
    name: PageNames.UniformedPatrol,
    path: RoutePaths.UniformedPatrol,
    icon: <i className={`${faWeight} fa-siren-on`} />,
    description: 'Secure your facilities with certified, licensed, highly trained professional uniformed officers.',
    image: uniformedPatrolImage,
    showInGrid: true,
  },
  {
    name: PageNames.B2B,
    path: RoutePaths.B2B,
    icon: <i className={`${faWeight} fa-handshake`} />,
    description: 'Our industry expertise can help painlessly take your business to the next level.',
    image: b2bImage,
    showInGrid: true,
  },
];

export { servicesMenuItemsData };

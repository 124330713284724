enum PageNames {
  Epic = 'Quantum EPIC™',
  EP = 'Executive Protection',
  Intelligence = 'Intelligence',
  Command = 'Quantum Command',
  Fire = 'Fire Suppression',
  Consulting = 'Consulting',
  EventSecurity = 'Event Security',
  Investigations = 'Investigations',
  Transport = 'High Value Transport',
  UniformedPatrol = 'Uniformed Patrol',
  B2B = 'Business to Business',
  Company = 'Company',
  Contact = 'Contact',
  Login = 'Login',
  Covid19 = 'Covid-19',
}

export default PageNames;
